
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Name" },
      { field: "StartTime" },
      { field: "EndTime" },
      { field: "Ordinal" },
    ];

    const gridOptions: GridOptions = {
      id: "user",
      title: "國家",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "國家清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "國家清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: "包含" }], slots: { filter: "filter-name" }
        },
        {
          field: "Continent",
          title: "國家所屬洲",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.Continent).find(e => e.Value === cellValue)?.Name : undefined
        },
        {
          field: 'StartTime', title: '開始時間', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        {
          field: 'EndTime', title: '結束時間', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true, resizable: false }
      ],
      decideRowOperable: (row, operation) => row.DataMode !== 4 || operation === 'read',
      promises: {
        query: model ? (params) => model.dispatch("country/query", params) : undefined,
        queryAll: model ? () => model.dispatch("country/query") : undefined,
        save: model ? (params) => model.dispatch("country/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "Continent", title: "國家所屬洲", span: 12, itemRender: { name: "$select", props: { placeholder: "洲別" }, options: model ? Object.values(model.enums.Continent).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Ordinal", title: "排序", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入數值" } } },
        { field: "StartTime", title: "開始時間", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "EndTime", title: "結束時間", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Description", title: "說明", span: 24, itemRender: { name: '$textarea', props: { placeholder: '請輸入說明', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        Name: [{ required: true }],
        Continent: [{ required: true }],
        StartTime: [{ required: true }],
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
